<template>
  <el-dialog
      :title="'修改'"
      :close-on-click-modal="false"
      v-if="visible"
      :visible.sync="visible">
      <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px">
        <el-form-item label="项目id" prop="id">
          <el-input v-model="dataForm.id" placeholder="项目id" disabled></el-input>
        </el-form-item>
        <el-form-item label="项目名称" prop="name">
          <el-input v-model="dataForm.name" placeholder="项目名称"></el-input>
        </el-form-item>
        <el-form-item label="地址信息" prop="address">
<!--          <el-input v-model="dataForm.address" placeholder="地址信息"></el-input>-->
          <el-cascader :options="areaSelectData" class="full-width" size="large" @change="handleChangeForQuery" :clearable="true" style="width: 300px; height: 32px"
                       filterable v-model="selectedOptionsForQuery" placeholder="请选择地址" />
        </el-form-item>
        <el-form-item label="产量信息" prop="yield">
          <el-input v-model="dataForm.yield" placeholder="产量信息"></el-input>
        </el-form-item>
        <el-form-item label="煤质信息" prop="coalquality">
          <el-input v-model="dataForm.coalquality" placeholder="煤质信息"></el-input>
        </el-form-item>
        <el-form-item label="煤层埋深" prop="miningmethod">
          <el-input v-model="dataForm.miningmethod" placeholder="煤层埋深"></el-input>
        </el-form-item>
        <el-form-item label="煤层倾角" prop="disastertype">
          <el-input v-model="dataForm.disastertype" placeholder="煤层倾角"></el-input>
        </el-form-item>
        <el-form-item label="服务年限" prop="lengthofservice">
          <el-input v-model="dataForm.lengthofservice" placeholder="服务年限"></el-input>
        </el-form-item>

        <el-form-item label="摺曲影响" prop="foldImpact">
          <el-select v-model="dataForm.foldImpact" placeholder="请选择">
            <el-option
                v-for="item in foldImpactOptions"
                :key="item.value"
                :label="item.label"
                :value="item.label"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="断层影响" prop="faultImpact">
          <el-select v-model="dataForm.faultImpact" placeholder="请选择">
            <el-option
                v-for="item in faultImpactOptions"
                :key="item.value"
                :label="item.label"
                :value="item.label"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="陷落柱影响" prop="trapColumnImpact">
          <el-select v-model="dataForm.trapColumnImpact" placeholder="请选择">
            <el-option
                v-for="item in trapColumnImpactOptions"
                :key="item.value"
                :label="item.label"
                :value="item.label"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="围岩稳定性" prop="surroundingRockStability">
          <el-select v-model="dataForm.surroundingRockStability" placeholder="请选择">
            <el-option
                v-for="item in surroundingRockStabilityOptions"
                :key="item.value"
                :label="item.label"
                :value="item.label"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="煤层自燃倾向性" prop="seamSpontaneousCombustionPropensity">
          <el-select v-model="dataForm.seamSpontaneousCombustionPropensity" placeholder="请选择">
            <el-option
                v-for="item in seamSpontaneousCombustionPropensityOptions"
                :key="item.value"
                :label="item.label"
                :value="item.label"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="瓦斯等级" prop="gasGrade">
          <el-select v-model="dataForm.gasGrade" placeholder="请选择">
            <el-option
                v-for="item in gasGradeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.label"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="矿井水文地质条件" prop="mineConditionsHydrogeological">
          <el-select v-model="dataForm.mineConditionsHydrogeological" placeholder="请选择">
            <el-option
                v-for="item in mineConditionsHydrogeologicalOptions"
                :key="item.value"
                :label="item.label"
                :value="item.label"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="冲击倾向性" prop="impactPropensity">
            <el-select v-model="dataForm.impactPropensity" placeholder="请选择">
              <el-option
                  v-for="item in impactPropensityOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
              >
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="煤尘爆炸危险性" prop="coalDustExplosionRisk">
          <el-select v-model="dataForm.coalDustExplosionRisk" placeholder="请选择">
            <el-option
                v-for="item in coalDustExplosionRiskOptions"
                :key="item.value"
                :label="item.label"
                :value="item.label"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="热害等级" prop="heatDamageGrade">
          <el-select v-model="dataForm.heatDamageGrade" placeholder="请选择">
            <el-option
                v-for="item in heatDamageGradeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.label"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="备注" prop="remark">
          <el-input v-model="dataForm.remark" placeholder="备注" type="textarea"></el-input>
        </el-form-item>
    </el-form>
    <span slot="footer" class=" dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { isPositiveNum, isPositiveTwoDecimal } from '@/utils/validate'
import {regionData, CodeToText, TextToCode} from 'element-china-area-data';// 地址级联选择器
export default {
  name: "updateCoal",
  data() {
    var validateIsPositiveNum = (rule, input, callback) => {
      if (!isPositiveNum(input)) {
        callback(new Error('请输入正整数'))
      } else {
        callback()
      }
    }
    var validateIsPositiveTwoDecimal = (rule, input, callback) => {
      if (!isPositiveTwoDecimal(input)) {
        callback(new Error('请输入最多两位正小数'))
      } else {
        callback()
      }
    }
    return {
      areaSelectData: regionData, // options绑定的数据就是引入的 provinceAndCityData
      selectedOptions: [], // 地区选择参数，['省区域码', '市区域码']
      selectedOptionsForQuery: [],
      foldImpactOptions: [
        {
          value: '选项1',
          label: '影响很小',
        },
        {
          value: '选项2',
          label: '影响较大',
        },
        {
          value: '选项3',
          label: '影响很大',
        }
      ],
      faultImpactOptions: [
        {
          value: '选项1',
          label: '影响很小',
        },
        {
          value: '选项2',
          label: '影响较大',
        },
        {
          value: '选项3',
          label: '影响很大',
        }
      ],
      trapColumnImpactOptions: [
        {
          value: '选项1',
          label: '影响很小',
        },
        {
          value: '选项2',
          label: '影响较大',
        },
        {
          value: '选项3',
          label: '影响很大',
        }
      ],
      surroundingRockStabilityOptions: [
        {
          value: '选项1',
          label: '围岩稳定，对采掘影响很小',
        },
        {
          value: '选项2',
          label: '围岩稳定，对采掘有一定影响',
        },
        {
          value: '选项3',
          label: '围岩稳定，对采掘影响较大',
        }
      ],
      seamSpontaneousCombustionPropensityOptions: [
        {
          value: '选项1',
          label: '不易自燃层',
        },
        {
          value: '选项2',
          label: '易自燃层',
        },
        {
          value: '选项3',
          label: '极易自燃层',
        }
      ],
      gasGradeOptions: [
        {
          value: '选项1',
          label: '低瓦斯矿井',
        },
        {
          value: '选项2',
          label: '高瓦斯矿井',
        },
        {
          value: '选项3',
          label: '煤与瓦斯突出矿井',
        }
      ],
      mineConditionsHydrogeologicalOptions: [
        {
          value: '选项1',
          label: '水文地质条件简单',
        },
        {
          value: '选项2',
          label: '水文地质条件中等',
        },
        {
          value: '选项3',
          label: '水文地质条件复杂',
        },
        {
          value: '选项4',
          label: '水文地质条件及其复杂',
        }
      ],
      impactPropensityOptions: [
        {
          value: '选项1',
          label: '无冲击',
        },
        {
          value: '选项2',
          label: '弱冲击',
        },
        {
          value: '选项3',
          label: '强冲击',
        }
      ],
      coalDustExplosionRiskOptions: [
        {
          value: '选项1',
          label: '无煤尘爆炸危险性',
        },
        {
          value: '选项2',
          label: '弱煤尘爆炸危险性',
        },
        {
          value: '选项3',
          label: '强煤尘爆炸危险性',
        }
      ],
      heatDamageGradeOptions: [
        {
          value: '选项1',
          label: '无热害矿井',
        },
        {
          value: '选项2',
          label: '一级热害矿井',
        },
        {
          value: '选项3',
          label: '二级热害矿井',
        },
        {
          value: '选项4',
          label: '三级热害矿井',
        }
      ],


      visible: false,
      dataForm: {
        id: undefined,
        name: undefined,
        address: undefined,
        yield: undefined,
        coalquality: undefined,
        miningmethod: undefined,
        disastertype: undefined,
        lengthofservice: undefined,
        foldImpact: undefined,
        faultImpact: undefined,
        trapColumnImpact: undefined,
        surroundingRockStability: undefined,
        seamSpontaneousCombustionPropensity: undefined,
        gasGrade: undefined,
        mineConditionsHydrogeological: undefined,
        impactPropensity: undefined,
        coalDustExplosionRisk: undefined,
        heatDamageGrade: undefined,
        remark: undefined
      },

      dataRule: {
        id: [
          { required: true, message: 'id不能为空', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '名称不能为空', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '地址不能为空', trigger: 'blur' }
        ],
        yield: [
          { required: true, message: '产量不能为空', trigger: 'blur' },
          { validator: validateIsPositiveTwoDecimal, trigger: 'blur' }
        ],
        coalquality: [
          { required: true, message: '煤质不能为空', trigger: 'blur' }
        ],
        miningmethod: [
          { required: true, message: "请输入煤层深度", trigger: "blur" },
          { validator: validateIsPositiveTwoDecimal, trigger: 'blur' }
        ],
        disastertype: [
          { required: true, message: "请输入煤层倾角", trigger: "blur" },
          { validator: validateIsPositiveTwoDecimal, trigger: 'blur' }
        ],
        lengthofservice: [
          { required: true, message: '服务年限不能为空', trigger: 'blur' },
          { validator: validateIsPositiveNum, trigger: 'blur' }
        ],
        remark: [
          { required: true, message: '描述不能为空', trigger: 'blur' }
        ],
        foldImpact: [
          { required: true, message: "请选择摺曲影响", trigger: "blur" },
        ],
        faultImpact: [
          { required: true, message: "请选择断层影响", trigger: "blur" },
        ],
        trapColumnImpact: [
          { required: true, message: "请选择陷落柱影响", trigger: "blur" },
        ],
        surroundingRockStability: [
          { required: true, message: "请选择围岩稳定性", trigger: "blur" },
        ],
        seamSpontaneousCombustionPropensity: [
          { required: true, message: "请选择煤层自燃倾向性", trigger: "blur" },
        ],
        gasGrade: [
          { required: true, message: "请选择瓦斯等级", trigger: "blur" },
        ],
        mineConditionsHydrogeological: [
          { required: true, message: "请选择矿井水文地质条件", trigger: "blur" },
        ],
        impactPropensity: [
          { required: true, message: "请选择冲击倾向性", trigger: "blur" },
        ],
        coalDustExplosionRisk: [
          { required: true, message: "请选择煤尘爆炸危险性", trigger: "blur" },
        ],
        heatDamageGrade: [
          { required: true, message: "请选择热害等级", trigger: "blur" },
        ]
      },
    }
  },
  methods: {
    init (row) {
      this.dataForm.id = row.id
      // this.dataForm.name = row.name
      // this.dataForm.address = row.address
      // this.dataForm.yield = row.yield
      // this.dataForm.coalquality = row.coalquality
      // this.dataForm.miningmethod = row.miningmethod
      // this.dataForm.disastertype = row.disastertype
      // this.dataForm.lengthofservice = row.lengthofservice
      // this.dataForm.remark = row.remark

      this.getRequest('/coalManage/getCoalInfoByCoalId?coalId=' + this.dataForm.id).then((resp) => {
        if (resp) {
          console.log(resp.data)
          this.dataForm.name = resp.data.name;
          this.dataForm.address = resp.data.address;
          var str = resp.data.address.split(" ")
          console.log(TextToCode[str[0]][str[1]][str[2]].code)
          this.selectedOptionsForQuery = TextToCode[str[0]][str[1]][str[2]].code
          // this.selectedOptionsForQuery.push(TextToCode[str[0]].code)
          // this.selectedOptionsForQuery.push(TextToCode[str[1]].code)
          // this.selectedOptionsForQuery.push(TextToCode[str[2]].code)
          this.dataForm.coalquality = resp.data.coalquality;
          this.dataForm.disastertype = resp.data.disasterType;
          this.dataForm.miningmethod = resp.data.miningMethod;
          this.dataForm.yield = resp.data.yield;
          this.dataForm.lengthofservice = resp.data.lengthOfService;
          this.dataForm.remark = resp.data.remark;
          this.dataForm.foldImpact = resp.data.foldImpact
          this.dataForm.faultImpact = resp.data.faultImpact
          this.dataForm.trapColumnImpact = resp.data.trapColumnImpact
          this.dataForm.surroundingRockStability = resp.data.surroundingRockStability
          this.dataForm.seamSpontaneousCombustionPropensity = resp.data.seamSpontaneousCombustionPropensity
          this.dataForm.gasGrade = resp.data.gasGrade
          this.dataForm.mineConditionsHydrogeological = resp.data.mineConditionsHydrogeological
          this.dataForm.impactPropensity = resp.data.impactPropensity
          this.dataForm.coalDustExplosionRisk = resp.data.coalDustExplosionRisk
          this.dataForm.heatDamageGrade = resp.data.heatDamageGrade
        }
      })

      this.visible = true
    },
    dataFormSubmit () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.postRequest('/coalManage/updateCoalInfoById', this.dataForm).then((resp) => {
            if(resp) {
              this.visible = false
              this.$emit('refreshDataList')
              // this.$emit('refreshDataList')
              // this.visible = true
            }
          })
        }
      })
    },
    handleChangeForQuery() {
      if (this.selectedOptionsForQuery[0] !== undefined) {
        this.dataForm.address = CodeToText[this.selectedOptionsForQuery[0]] + ' ' + CodeToText[this.selectedOptionsForQuery[1]] + ' ' + CodeToText[this.selectedOptionsForQuery[2]]

      } else {
        this.dataForm.address = ''
      }
    }
  }
}
</script>

<style scoped>

</style>
